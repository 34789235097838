import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import dateConversion from "../helpers/dateConversion";
import "../css/News.css";
import useSessionStorage from "../helpers/useSessionStorage";
import useLocalStorage from "../helpers/useLocalStorage";
import jwt from "jwt-decode";

const News = ({ title }) => {
    const token = useLocalStorage("token")[0];
    const admin = token !== "undefined" ? jwt(token).admin : 0;
    const [news, setNews] = useState({});
    const nws = useSessionStorage("news");
    const [d, setD] = useState("");
    const date = d && d.length > 4
        ? `${d[0]} ${d[1]}, ${d[3]}`
        : d && `${d[0]} ${d[1]}, ${d[2]}`;
    
    useEffect(() => {
        const setNewsResource = async (title) => {
            const temp = await JSON.parse(nws[0]);
            for (let article of temp) {
                if (article.title === title) {
                    setNews(article);
                    setD(dateConversion(article.date).split(" "));
                }
            }
        };
        setNewsResource(title);
    }, [title]);

    return (
        <div className="newsDiv">
            <div className="infoDiv">
                <h1 className="titleH1">{news.title}</h1>
                <h3 className="dateH3">{date}</h3>
            </div>
            <div className="contentDiv">
                <div className="leftContentDiv">
                    {news.image ? <img className="infoImg" src={news.image} alt={`${news.title}-${date}`} /> : null}
                </div>
                <div className="rightContentDiv">
                    <ReactMarkdown className="contentContainer" remarkPlugins={[gfm, remarkBreaks]}>{news.content}</ReactMarkdown>
                </div>
            </div>
            {admin ? (
                <div className="buttonsDiv">
                    <p className="updateNewsBtn"><a href={`/news/update/${news.title}`} className="updateBtn" key="updateNewsBtn">Update Article</a></p>
                    <p className="deleteNewsBtn"><a href={`/news/delete/${news.title}`} className="deleteBtn" key="deleteNewsBtn">Delete Article</a></p>
                </div>
            ) : null}
        </div>
    );
}

export default News;
