import React, {useEffect, useState} from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import BookReviewComment from "./BookReviewComment";
import dateConversion from "../helpers/dateConversion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faStar as solidFaStar} from "@fortawesome/free-solid-svg-icons";
import {faStar as regularFaStar} from "@fortawesome/free-regular-svg-icons";
import jwt from "jwt-decode";
import useLocalStorage from "../helpers/useLocalStorage";
import useSessionStorage from "../helpers/useSessionStorage";
import api from "../helpers/api";
import "../css/Review.css";

const Review = ({title}) => {
    const url = window.location.href.split("/");
    let reviewTitle;
    if(title !== undefined){
        reviewTitle = title;
    } else {
        reviewTitle = url[url.length-1].replaceAll("%20", " ");
    }
    const conditionalTitle = url[url.length-1].replaceAll("%20", " ");
    const token = useLocalStorage("token")[0];
    const admin = token !== "undefined" ? jwt(token).admin : 0;
    const username = token !== "undefined" ? jwt(token).username : null;
    const [review, setReview] = useState({});
    const [commentsAreActive, setCommentsToActive] = useState(false);
    const [comments, setComments] = useState([]);
    const rvws = useSessionStorage("reviews");
    const d = Object.keys(review).length > 0 ? dateConversion(review.date).split(" ") : null;
    const date = d && d.length > 4
        ? `${d[0]} ${d[1]}, ${d[3]}`
        : d && `${d[0]} ${d[1]}, ${d[2]}`;
    const rd = Object.keys(review).length > 0 ? dateConversion(review.release_date).split(" ") : null;
    const releaseDate = Object.keys(review).length > 0 ?  `${rd[0]} ${rd[1]} ${rd[2]}` : "";
    const rating = Object.keys(review).length > 0 ? review.rating : null;
    const setReviewResources = async () => {
        const temp = JSON.parse(rvws[0]);
        for(let rvw of temp){
            if(rvw.title === reviewTitle){
                setReview(rvw);
            }
        }
    }
    useEffect(()=> {
        setReviewResources();
    }, []);
    const gatherComments = async (title) => {
        const com = await api.getByTitleOrUsername("bookReviewComments", title);
        if(com.length > 0){
            let temp = [];
            for(let comment of com){
                temp.push(comment);
            }
            setComments(temp);
        }
    }
    useEffect(() => {
        
    }, [d])
    useEffect(()=>{
        gatherComments(review.title);
    }, [review.title]);
    const starsArr = [];
    const contentStarsArr = [];
    const flowStarsArr = [];
    const editStarsArr = [];
    const stars = () => {
        for(let i=0; i < 5; i++){
            starsArr.push(<p className="starRating" key={`empty-star-${i}`}><FontAwesomeIcon id="iconBtn" className="ratingIcon" icon={regularFaStar} /></p>);
            contentStarsArr.push(<p className="starRating" key={`empty-star-${i}`}><FontAwesomeIcon id="iconBtn" className="ratingIcon" icon={regularFaStar} /></p>);
            flowStarsArr.push(<p className="starRating" key={`empty-star-${i}`}><FontAwesomeIcon id="iconBtn" className="ratingIcon" icon={regularFaStar} /></p>);
            editStarsArr.push(<p className="starRating" key={`empty-star-${i}`}><FontAwesomeIcon id="iconBtn" className="ratingIcon" icon={regularFaStar} /></p>);
        }
        for(let i = 0; i < rating; i++){
            starsArr.unshift(<p className="starRating" key={`star-${i}`}><FontAwesomeIcon id="iconBtn" className="ratingIcon" icon={solidFaStar} /></p>);
            starsArr.pop();
        }
        for(let i = 0; i < review.content_rating; i++){
            contentStarsArr.unshift(<p className="starRating" key={`star-${i}`}><FontAwesomeIcon id="iconBtn" className="ratingIcon" icon={solidFaStar} /></p>);
            contentStarsArr.pop();
        }
        for(let i = 0; i < review.flow_rating; i++){
            flowStarsArr.unshift(<p className="starRating" key={`star-${i}`}><FontAwesomeIcon id="iconBtn" className="ratingIcon" icon={solidFaStar} /></p>);
            flowStarsArr.pop();
        }
        for(let i = 0; i < review.edit_rating; i++){
            editStarsArr.unshift(<p className="starRating" key={`star-${i}`}><FontAwesomeIcon id="iconBtn" className="ratingIcon" icon={solidFaStar} /></p>);
            editStarsArr.pop();
        }
    }
    stars();
    const returnStatement = Object.keys(review).length > 0 ? 
        <div className="reviewDiv">
            <div className="top">
                <div className="reviewLeft">
                    {url[url.length-1].replaceAll("%20", " ") !== reviewTitle ? <a href={`/reviews/${review.title}`}><img className="bookReviewInfoImg" src={review.image} alt={`${review.title}-${date}`}/></a> : <img className="bookReviewInfoImg" src={review.image} alt={`${review.title}-${date}`}/>}
                    {url[url.length-1].replaceAll("%20", " ") !== reviewTitle ? <p className="imgNote">Cick image to visit page</p> : null}
                </div>
                <div className="reviewRight">
                    <h2 className="reviewTitle">{review.title}</h2>
                    {review.series !== "" ? <h4 className="reviewSeries">{review.series} book {review.book_number}</h4> : null}
                    <p className="reviewAuthor">By {review.author}</p>
                    <div className="ratingDiv">
                        <p className="bookRating"><strong>Rating</strong></p>
                        {starsArr} ({rating})
                    </div>
                    <div className="ratingDiv">
                        <p className="bookRating">Content Rating</p>
                        {contentStarsArr} ({review.content_rating})
                    </div>
                    <div className="ratingDiv">
                        <p className="bookRating">Flow Rating</p>
                        {flowStarsArr} ({review.flow_rating})
                    </div>
                    <div className="ratingDiv">
                        <p className="bookRating">Edit Rating</p>
                        {editStarsArr} ({review.edit_rating})
                    </div>
                    <p className="reviewDate"><strong>Reviewed On</strong></p>
                    <p className="reviewDate">{date}</p>
                    <p className="reviewRelease"><strong>Original Release Date</strong></p>
                    <p className="reviewRelease">{releaseDate}</p>
                    <p className="reviewPublisher"><strong>Publisher</strong></p>
                    <p className="reviewPublisher">{review.publisher}</p>
                    <a className="reviewLink bookLink" href={review.book_link}>Available on Amazon</a>
                </div>
            </div>
            <div className={`bottom`}>
                <ReactMarkdown remarkPlugins={[gfm,remarkBreaks]} className="contentDiv">{review.review}</ReactMarkdown>
                {review.youtube_link ? <a className="reviewYoutube" href={review.youtube_link}>Watch on Youtube</a> : null}
            </div>
            <div className="buttonsDiv">
                {admin ? <p><a href={`/reviews/update/${review.title}`} className="updateBtn" key="updateReviewBtn">Update Book Review</a></p> : null}
                {admin ? <p><a href={`/reviews/delete/${review.title}`} className="deleteBtn" key="deleteReviewBtn">Delete Book Review</a></p> : null}
            </div>
            {/* {username !== null && url[url.length-1] !== "" ? <p id="reviewAddCommentBtn"><a href={`/bookReviewComments/new/${review.title}`} className="newContentBtn">Add Comment</a></p> : null}
            {comments.length > 0 && conditionalTitle === review.title  ? <p>User Comments <button onClick={() => setCommentsToActive(current => !current)} className="showHideBtn">{commentsAreActive ? "Hide Comments" : "Show Comments"}</button></p> : null} */}
            {/* <div className={`bookReviewCommentDiv ${commentsAreActive ? "" : "hidden"}`}>
                {comments.length > 0 ? comments.map(c => <BookReviewComment comment={c} key={`${c.username}-${c.id}`} />) : null}
            </div> */}
        </div> : <h1 className="loadingMsg">Loading</h1>
    return returnStatement;
}

export default Review;