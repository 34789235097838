import React, {useEffect, useState} from "react";
import jwt from "jwt-decode";
import useLocalStorage from "../helpers/useLocalStorage";
import api from "../helpers/api";
import Blog from "./Blog";
import "../css/BlogPage.css";
import useSessionStorage from "../helpers/useSessionStorage";

const BlogPage = () => {
    const token = useLocalStorage("token")[0];
    const admin = token !== "undefined" ? jwt(token).admin : 0;
    const username = token !== "undefined" ? jwt(token).username : null;
    const [blogs, setBlogs] = useState([]);
    const [blgs, setBlgs] = useSessionStorage("blogs");
    const b = blgs !== "undefined" && blgs !== undefined ? JSON.parse(blgs) : "";
    const sortedBlogs = b.sort((a,b) => (Date.parse(a.date) < Date.parse(b.date)) ? 1 : (Date.parse(a.date) > Date.parse(b.date)) ? -1 : 0);

    useEffect(() => {
        const setResources = async() => {
            const temp = [];
            if(b !== ""){
                setBlogs(sortedBlogs);
            } else {
                const b = await api.getAll("blog");
                for(let i = 0; i < b.length; i++){
                    temp.push(b[i]);
                }
                const sortedTemp = temp.sort((a, b) => (Date.parse(a.date) > Date.parse(b.date)) ? 1 : (a.date < b.date) ? -1 : 0);
                setBlgs(JSON.stringify(sortedTemp));
                if(blgs.length > 0){
                    setBlogs(JSON.parse(blgs[0]));
                } else {
                    setBlogs([]);
                }
            }
        }
        setResources();
    }, []);
    return(
        <>
            <h1>Blogs</h1>
            {admin ? <p><a href="/blog/new" className="newContentBtn" key="newContentBtn">Add New Blog</a></p> : null}
            {blogs ? blogs.map(b => b.title !== undefined ? <Blog title={b.title} key={`blog-${b.title}-${b.date}`} /> : null) : null}
        </>
    )
}

export default BlogPage;