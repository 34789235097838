import React from "react";
import DataFetcher from "./DataFetcher";
import Carousel from "./Carousel";
import useSessionStorage from "../helpers/useSessionStorage";
import Book from "./Book";
import News from "./News";
import Blog from "./Blog";
import Review from "./Review";
import "../css/HomePage.css";

const HomePage = () => {
    const [books] = useSessionStorage("books");
    const [news] = useSessionStorage("news");
    const [blogs] = useSessionStorage("blogs");
    const [reviews] = useSessionStorage("reviews");
    const b = JSON.parse(blogs);
    const n = JSON.parse(news);
    const r = JSON.parse(reviews);
    const sortedBlogs = b.sort((a,b) => Date.parse(b.date) - Date.parse(a.date));
    const sortedNews = n.sort((a,b) => Date.parse(b.date) - Date.parse(a.date));
    const sortedReviews = r.sort((a,b) => Date.parse(b.date) - Date.parse(a.date));

    const featuredBook = books ? JSON.parse(books).find(book => book.featured) : null;
    const latestNews = news ? sortedNews[0] : null;
    const latestBlog = blogs ? sortedBlogs[0] : null;
    const latestReview = reviews ? sortedReviews[0] : null;
    const interval = 15000;

    const carouselItems = [
        featuredBook && <Book title={featuredBook.title} key="featuredBook" />,
        latestNews && <News title={latestNews.title} key="latestNews" />,
        latestBlog && <Blog title={latestBlog.title} key="latestBlog" />,
        latestReview && <Review title={latestReview.title} key="latestReview" />
    ].filter(Boolean);  // Remove null entries

    return (
        <DataFetcher>
            <div id="homePage">
                <Carousel items={carouselItems} interval={interval} />
            </div>
        </DataFetcher>
    );
}

export default HomePage;
