import React, {useEffect, useState} from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import dateConversion from "../helpers/dateConversion";
import BlogComment from "../components/BlogComment";
import jwt from "jwt-decode";
import useLocalStorage from "../helpers/useLocalStorage";
import useSessionStorage from "../helpers/useSessionStorage";
import api from "../helpers/api";
import "../css/Blog.css";

const Blog = ({title}) => {
    const url = window.location.href.split("/");
    let blogTitle;
    if(title !== undefined){
        blogTitle = title;
    } else {
        blogTitle = url[url.length-1].replaceAll("%20", " ");
    }
    const conditionalTitle = url[url.length-1].replaceAll("%20", " ");
    const token = useLocalStorage("token")[0];
    const admin = token !== "undefined" ? jwt(token).admin : 0;
    const username = token !== "undefined" ? jwt(token).username : null;
    const [blog, setBlog] = useState({});
    const [isActive, setIsActive] = useState(false);
    const [comments, setComments] = useState([]);
    const blgs = useSessionStorage("blogs");
    const d = Object.keys(blog).length > 0 ? dateConversion(blog.date).split(" ") : null;
    const date = d && d.length > 4
        ? `${d[0]} ${d[1]}, ${d[3]}`
        : d && `${d[0]} ${d[1]}, ${d[2]}`;
    const displayImage = url[url.length-1].replaceAll("%20", " ") !== blogTitle ? <a href={`/blog/${blog.title}`}><img className="infoImg" src={blog.image} alt={`${blog.title}-${date}`}/></a> : <img className="infoImg" src={blog.image} alt={`${blog.title}-${date}`}/>;
    const setBlogResources = async() => {
        const temp = JSON.parse(blgs[0]);
        for(let blg of temp){
            if(blg.title === blogTitle){
                setBlog(blg);
            }
        }
    }
    useEffect(() => {
        setBlogResources();
    }, []);
    const gatherComments = async (title) => {
        const resources = await api.getByTitleOrUsername("blogComments", title);
        if(resources.length > 0){
            let temp = [];
            for(let i = 0; i < resources.length; i++){
                temp.push(resources[i]);
            }
            setComments(temp);
        }
    }

    useEffect(() => {
        gatherComments(blog.title);
    }, [blog.title]);
    const returnStatement = Object.keys(blog).length > 0 ? 
        <div className="blogDiv">
            <div className="infoDiv">
                <h1 className="titleH1">{blog.title}</h1>
                <h3 className="dateH3">{date}</h3>
                <div className="leftBlogContentDiv">
                    {blog.image ? displayImage : null}
                    {blog.image && url[url.length-1].replaceAll("%20", " ") !== blogTitle ? <p className="imgNote">Cick image to visit page</p> : null}
                </div>
                <div className="rightBlogContentDiv">
                    <ReactMarkdown className="contentContainer" remarkPlugins={[gfm, remarkBreaks]}>{blog.content}</ReactMarkdown>                
                </div>
            </div>
            {admin ? <div className="buttonsDiv"> 
                <p><a href={`/blog/update/${blog.title}`} className="updateBtn" key="updateBlogBtn">Update Blog</a></p>
                <p><a href={`/blog/delete/${blog.title}`} className="deleteBtn" key="DeleteBlogBtn">Delete Blog</a></p>
            </div> : null}
            {/* {username !== null && url[url.length-1] !== "" ? <p className="addCommentBtn"><a href={`/blogComment/new/${blog.title}`} className="newContentBtn">Add Comment</a></p> : null}
            {comments.length > 0 && conditionalTitle === blog.title ? <p>User Comments <button onClick={() => setIsActive(current => !current)} className="showHideBtn">{isActive ? "Hide Comments" : "Show Comments"}</button></p> : null}
            <div className={`blogCommentsDiv ${isActive ? "" : "hidden"}`}>
                {comments.length > 0 ? comments.map(c => <BlogComment comment={c} key={`${c.username}-${c.id}`} />) : null}
            </div> */}
        </div> : <h1 className="loadingMsg">Loading</h1>
    return returnStatement;
}

export default Blog